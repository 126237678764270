<template>
    <v-container class="pa-0 transparent" fluid>
        <v-card class="pa-0 ma-0 transparent" elevation="0">

            <v-card-title class="pa-2 ma-0 pt-5 pb-5">
     <p>{{$t('accounting.lang_monthlyReportDescrBody')}}</p>
            </v-card-title>

            <v-card-text class="ma-0 pa-0 transparent elevation-0">

                        <monthly-archive-component />

            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import MonthlyArchiveComponent from "./MonthlyArchiveComponent";
    export default {
        name: "MonthlyReportComponent",
        components: {MonthlyArchiveComponent},
        data: () => {
            return {
                tabs: 0
            }
        }
    }
</script>

<style scoped>
    .transparent {
        background-color: transparent !important;
    }
</style>