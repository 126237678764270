<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="5">
        <v-text-field v-model="startMonth" :disabled="loading"
                      :label="$t('generic.lang_from')"
                      :loading="loading"
                      dense
                      outlined
                      clearable
                      type="month"/>
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field v-model="endMonth" :disabled="loading"
                      :label="$t('generic.lang_to')"
                      :loading="loading"
                      dense
                      outlined
                      clearable
                      type="month"/>
      </v-col>

      <v-col class="pt-2" cols="12" sm="2">
        <v-btn block class="mx-auto elevation-0" color="primary" :loading="loading" :disabled="loading" @click="filter()">
          <v-icon>filter_list</v-icon>
          {{ $t("generic.lang_filter") }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ACCOUNTING.MONTHLYREPORT.ARCHIVE"
            :datatable-headers="this.datatableHeaders"
            :elevation="6"
            :data="params"
            :excel-columns="excelColumns"
            @displayEntry="displayEntry"
            excel-file-name="daily-report-archive"
            pdf-title="Daily report archive"
            ref="monthlyArchive"
            show-display-buttons
            show-pdf
            show-select-checkbox
            pdf-orientation="landscape"
        >
          <template v-slot:item.total="{item}">
            {{ item.total | currency }}
          </template>
          <template v-slot:item.subtotal="{item}">
            {{ item.subtotal | currency }}
          </template>
          <template v-slot:item.tax="{item}">
            {{ item.tax | currency }}
          </template>
          <template v-slot:item.ec="{item}">
            {{ item.ec | currency }}
          </template>
          <template v-slot:item.cash="{item}">
            {{ item.cash | currency }}
          </template>
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import Datatable from "../../datatable/Datatable";

export default {
  name: "MonthlyArchiveComponent",
  components: {Datatable},
  data: () => {
    return {
      ENDPOINTS,
      startMonth:null,
      endMonth:null,
      loading:false,
    }
  },
  methods: {
    displayEntry(entry) {
      this.$router.push('/accounting/monthlyReport/details/' + entry.id);
    },
    filter() {
      this.$refs.monthlyArchive.getDataFromApi();
    },
  },

  computed: {
    params() {
      return {
        start_month: this.startMonth,
        end_month: this.endMonth,
      }
    },
    datatableHeaders: function () {
      return [
        {
          text: "ID",
          value: "id",
          sortable: false,
          hide: true
        },
        {
          text: this.$t("accounting.lang_monthlyAbID"),
          value: "reportID",
          sort: "desc"
        },
        {
          text: this.$t("accounting.lang_dailyReportStart"),
          value: "start_date",
        },
        {
          text: this.$t("accounting.lang_dailyReportEnd"),
          value: "end_date"
        },

        {
          text: this.$t("generic.lang_cashierID"),
          value: "cashierID"
        },

        {
          text: this.$t("erp.lang_ware_total"),
          value: "total"
        },

        {
          text: this.$t("generic.lang_subtotal"),
          value: "subtotal"
        },

        {
          text: this.$t("erp.lang_mwst"),
          value: "tax"
        },

        {
          text: this.$t("generic.lang_ec"),
          value: "ec"
        },

        {
          text: this.$t("generic.lang_cash"),
          value: "cash"
        },
      ];
    },
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id"
        },
        {
          label: this.$t("accounting.lang_monthlyAbID"),
          field: "reportID"
        },
        {
          label: this.$t("generic.lang_Startpunkt"),
          field: "start_date"
        },
        {
          label: this.$t("generic.lang_endAt"),
          field: "end_date"
        },
        {
          label: this.$t("generic.lang_cashierID"),
          field: "cashierID"
        },

        {
          label: this.$t("erp.lang_grossSales"),
          field: "total"
        },

        {
          label: this.$t("generic.lang_subtotal"),
          field: "subtotal"
        },

        {
          label: this.$t("erp.lang_mwst"),
          field: "tax"
        },

        {
          label: this.$t("generic.lang_ec"),
          field: "ec"
        },

        {
          label: this.$t("generic.lang_cash"),
          field: "cash"
        },
      ];
    }
  },
}
</script>

<style scoped>
.buttons {
  min-width: 200px !important;
  max-width: 300px !important;
  height: 250px !important;
  cursor: pointer !important;
}

.icons {
  font-size: 100px !important;
}
</style>